import { NgModule } from '@angular/core';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
// import { AppTextInputComponent } from './components/text-input/text-input.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { AppPspRoleInputComponent } from './components/psp-role-input/psp-role-input.component';
// import { AppMultiSelectComponent } from './components/multi-select/multi-select.component';
import { AppCommaPipe } from './pipes/comma.pipe';
import { AppPopupComponent } from './components/popup/popup.component';
import { AppConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup.component';
import { AppPopupHostDirective } from './components/popup/popup-host.directive';
import { TranslateModule } from '@ngx-translate/core';
import { AppLoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
// import { AppSelectComponent } from './components/select/select.component';
// import { AppModifyTspPopupComponent } from './components/modify-tsp-popup/modify-tsp-popup.component';

const exportedComponents = [
    HeaderComponent,
    // AppTextInputComponent,
    // AppPspRoleInputComponent,
    // AppMultiSelectComponent,
    AppCommaPipe,
    AppPopupComponent,
    AppConfirmationPopupComponent,
    AppPopupHostDirective,
    // AppModifyTspPopupComponent,
    AppLoadingSpinnerComponent
    // AppSelectComponent
];

@NgModule({
    imports: [ RouterModule, TranslateModule.forChild(), CommonModule, FormsModule, ReactiveFormsModule ],
    declarations: [
        ...exportedComponents
    ],
    exports: [ ...exportedComponents, CommonModule, FormsModule, ReactiveFormsModule ]
})
export class PCMCommonModule { }