<header class="container-wrapper">
    <section class="container">
        <a class="logo" href="#">
            <img src="/assets/images/hsbc-logo-full.svg" alt="HSBC Logo">
        </a>
        <!-- <nav class="nav-links">
            <a [routerLink]="['/add-tsp']" routerLinkActive="active" class="nav-link">{{ 'header.nav.addTsp' | translate }}</a>
            <a [routerLink]="['/modify-tsp']" routerLinkActive="active" class="nav-link">{{ 'header.nav.modifyTsp' | translate }}</a>
        </nav> -->
    </section>
</header>