<nav>
  <div class="chevrons-nav-container">
    <span class="label">{{currentPageIndex + 1}} of {{pagesAmount}}</span>
    <div class="chevrons-container">
      <button class="chevron" [attr.disabled]="currentPageIndex === 0 ? true : null"
              (click)="changePage(currentPageIndex - 1)" aria-labelledby="previousPageLabel">
        <svg version="1.1" id="chevron_x5F_left-layer" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px" viewBox="0 0 18 18" style="enable-background:new 0 0 18 18;" xml:space="preserve"
             role="img" focusable="false" aria-hidden="true">
          <g id="chevron_x5F_left_1_" fill="#333">
	          <polygon points="13.804,17 5.806,9 13.804,1 12.108,1 4.108,9 12.108,17 	"/>
            <rect transform="matrix(6.123234e-17 -1 1 6.123234e-17 0 18)" style="fill:none;" width="18" height="18"/>
          </g>
        </svg>
        <span class="srt" id="previousPageLabel">Previous page</span>
      </button>
      <button class="chevron" [attr.disabled]="currentPageIndex === (pagesAmount - 1) ? true : null"
              (click)="changePage(currentPageIndex + 1)" aria-labelledby="nextPageLabel">
        <svg version="1.1" id="chevron_x5F_right-layer" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px" viewBox="0 0 18 18" style="enable-background:new 0 0 18 18;" xml:space="preserve"
             role="img" focusable="false" aria-hidden="true">
          <g id="chevron_x5F_right_1_" fill="#333">
	          <polygon points="4.196,17 12.194,9 4.196,1 5.892,1 13.892,9 5.892,17 	"/>
            <rect x="0" y="0" transform="matrix(6.558493e-11 -1 1 6.558493e-11 -5.893543e-10 18)" style="fill:none;"
                  width="18" height="18"/>
          </g>
        </svg>
        <span class="srt" id="nextPageLabel">Next page</span>
      </button>
    </div>
  </div>

</nav>
