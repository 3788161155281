<h1>{{ 'pages.modify.header' | translate }}</h1>

<div id="status_radio">
    <span>Status : </span>
    <input type="radio" id="radio1" name="status_radio" (change)="changeStatus('FAILED')" checked>
    <label for="radio1">FAILED</label>
    <input type="radio" id="radio2" name="status_radio" (change)="changeStatus('DELIVERED')">
    <label for="radio2">DELIVERED</label>
</div>

<section class="modify-tsp-list-container">
    <table id="table">
        <thead>
            <tr>
                <th class="checkBox">
                    <input type="checkbox" [checked]="isSelectAll" (change)="selectAll()" />
                </th>
                <th class="orgId">Message Id</th>
                <th class="msg">Message</th>
                <th class="date-time">Updated Datetime</th>
                <th class="status">Status</th>
                <th class="failedReason">Failure Reason</th>
                <th class="listener">Listener</th>
                <th class="retryCount">Retry Count</th>
                <th class="orgId">Next Retry Datetime</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="selectList.length; else emptyRow">
                <tr *ngFor="let row of selectList; let i = index">
                    <td class="checkBox">
                        <input type="checkbox" [checked]="row.isSelect" value="{{row.messageId}}"
                            (change)="selectOne(row)" />
                    </td>
                    <td class="orgId">{{ row.messageId }}</td>
                    <td class="msg" [id]="'msg'+i">
                        <div class="msg_div" [innerHTML]="this.msgFun(row.message)"></div>
                        <span class="msg_span" (click)="showMore($event)">...show more</span>
                    </td>
                    <td class="date-time">{{ row.updatedAt}}</td>
                    <td class="status">{{ row.status }}</td>
                    <td class="failedReason">
                        <div [class.msg_div]="this.failShow(row.failureReason)">{{row.failureReason}}</div>
                        <span class="msg_span" *ngIf="this.failShow(row.failureReason)" (click)="showMore($event)">...show more</span>
                    </td>
                    <td class="listener">{{ row.listener}}</td>
                    <td class="retryCount">{{ row.retryCount}}</td>
                    <td class="orgId">{{ row.nextRetry}}</td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</section>

<button [disabled]='!canResend' class="canReSend" (click)="resendFun()">Resend</button>
<button [disabled]='!canDelete' class="canReSend" (click)="deleteFun()">Delete</button>
<div>
    <piggy-pagination [currentPageIndex]="pageIndex" [pagesAmount]="pageAmount" (changePageEvent)="changePage($event)">
    </piggy-pagination>
</div>

<ng-template #emptyRow>
    <tr>
        <td colspan="8">
            {{ 'pages.common.table.emptyTspList' | translate }}
        </td>
    </tr>
</ng-template>