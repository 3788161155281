import { Component, ElementRef, Renderer2 } from '@angular/core';
import { RegulatedEntity } from 'src/common/models/regulated-entity';
import { ActivatedRoute } from '@angular/router';
import { Observable, combineLatest, Subject } from 'rxjs';
import { map, switchMap, filter, startWith } from 'rxjs/operators';
import { AppPopupService } from 'src/common/components/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../app.service';
import { HttpResponse, HttpEvent, HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-modify-tsp',
    templateUrl: './modify-tsp.component.html',
    styleUrls: ['./modify-tsp.component.scss']
})
export class ModifyTspComponent {

    canResend: boolean = false;
    canDelete: boolean = false;
    isSelectAll: boolean = false;
    selectList: any = [];
    selectedArray: any[] = [];

    pageIndex: any = 0;
    pageAmount: any = 1;
    pageSize: any = 1000;

    status: any = 'FAILED';
    headers: any = '';

    constructor(private ar: ActivatedRoute, private popup: AppPopupService, private translate: TranslateService,
        private appService: AppService, private elRef: ElementRef, private renderer2: Renderer2) {
        this.quiryMsg();
    }

    quiryMsg() {
        this.appService.getList(String(this.pageIndex + 1), this.pageSize, this.status).subscribe((resp) => {
            if (resp) {
                this.selectList = resp.body;
                let respPageAmount = resp.headers.get('totalNumberOfRecords');
                if (Number(respPageAmount) > 0) {
                    this.pageAmount = Math.ceil(Number(respPageAmount) / Number(this.pageSize));
                }
            }
        });
        this.canResend = false;
        this.canDelete = false;
    }

    changeStatus(type: string) {
        this.status = type;
        this.pageIndex = 0;
        this.quiryMsg();
    }

    changePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.quiryMsg();
    }

    selectOne(row) {
        row.isSelect = !row.isSelect;
        this.selectedArray = this.selectList.filter(el => el.isSelect);
        this.isSelectAll = this.selectList.length === this.selectedArray.length;
        this.canResendFun();
        this.canDeleteFun();
    }

    selectAll() {
        this.isSelectAll = !this.isSelectAll;
        this.selectedArray = [];
        this.selectList.forEach((element) => {
            element.isSelect = this.isSelectAll;
            if (this.isSelectAll) {
                this.selectedArray.push(element);
            }
        });
        this.canResendFun();
        this.canDeleteFun();
    }

    canResendFun() {
        this.canResend = this.selectedArray.length > 0 && !this.selectedArray.some(element => element.status === 'DELIVERED');
    }

    canDeleteFun() {
        this.canDelete = this.selectedArray.length > 0;
    }

    resendFun() {
        this.getTitleAndContentForPopup('popup.confirm.title', 'popup.confirm.resend.content').pipe(
            switchMap(([title, content]) => this.popup.openConfirmationPopup(title, content, 'Resend', 'Cancel')),
            filter(x => x === true),
            switchMap(() => this.appService.resendItem(this.selectedArray.map(el => el.messageId)))
        ).subscribe(() => {
            this.pageIndex = 0;
            this.popup.openMessagePopupFnF('popup.common.success.title', 'popup.resend.success.content', 'ok', this.quiryMsg.bind(this));
        });
    }

    deleteFun() {
        this.getTitleAndContentForPopup('popup.confirm.title', 'popup.confirm.delete.content').pipe(
            switchMap(([title, content]) => this.popup.openConfirmationPopup(title, content, 'Delete', 'Cancel')),
            filter(x => x === true),
            switchMap(() => this.appService.deleteItem(this.selectedArray.map(el => el.messageId)))
        ).subscribe(() => {
            this.pageIndex = 0;
            this.popup.openMessagePopupFnF('popup.common.success.title', 'popup.commonDelete.success.content', 'ok', this.quiryMsg.bind(this));
        });
    }

    reloadFun() {
        window.location.reload();
    }

    private getTitleAndContentForPopup(titleKey: string, contentKey: string) {
        return combineLatest(
            this.translate.get(titleKey),
            this.translate.get(contentKey)
        );
    }

    msgFun(message) {
        let msg = message.split(',');
        //msg.splice(msg.findIndex(item => item.indexOf('payload') != -1), 1);
        return msg.join(',\n');
    }

    showMore(index: any) {
        const eme = index.srcElement;
        eme.previousElementSibling.style = 'word-break : unset; height:auto;';
        eme.style.display = 'none';
    }

    failShow(txt: any) {
        if (txt != null && txt.length > 100) {
            return true;
        }
        return false;
    }
}
