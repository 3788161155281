import { Injectable } from "@angular/core";
import { Resolve } from '@angular/router';
import { RegulatedEntity } from 'src/common/models/regulated-entity';
import { AppService } from '../app.service';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppModifyTspResolver implements Resolve<RegulatedEntity[]>{

    constructor(private appService: AppService) {}
    
    resolve(route: import("@angular/router").ActivatedRouteSnapshot, state: import("@angular/router").RouterStateSnapshot): RegulatedEntity[] | import("rxjs").Observable<RegulatedEntity[]> | Promise<RegulatedEntity[]> {
        return null;
    }

}