import { Injectable } from '@angular/core';
import { AppUtility } from '../utility.class';
import { environment } from "../../environments/environment";
import jwt_decode from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class AppAuthService {

    accessToken: string;
    idToken: string;
    tokenType: string;
    expiresIn: number;

    userHasValidProfile: boolean = false;

    readonly SESSION_STORAGE_KEY = 'obhk-pcm-session';

    initialize() {
        let hashObject = AppUtility.parseLocationHashStringToObject(window.location.hash);
        if (hashObject === null || !hashObject.id_token) {
            // check if session store has it.
            hashObject = JSON.parse(AppUtility.getSessionStorageItem(this.SESSION_STORAGE_KEY));
        }
        if (hashObject !== null && hashObject.id_token) {
            if (this.isValidIdToken(hashObject.id_token)) {
                const { access_token, id_token, token_type, expires_in } = hashObject;
                this.accessToken = access_token;
                this.idToken = id_token;
                this.tokenType = token_type;
                this.expiresIn = +expires_in;
                AppUtility.setSessionStorageItem(this.SESSION_STORAGE_KEY, JSON.stringify(hashObject));
            } else {
                if (this.userHasValidProfile) {
                    // valid token but expired
                    window.location.assign(environment.LOGIN_URL);
                }
            }
        } else {
            // redirect to login page
            window.location.assign(environment.LOGIN_URL);
        }
    }

    private isValidIdToken(idToken: any): boolean {
        const decodedToken: any = jwt_decode(idToken);
        const allowedProfiles = environment.ALLOWED_PROFILES;
        this.userHasValidProfile = false;
        if (decodedToken && decodedToken.profile && decodedToken.profile.length > 0) {
            const trimmedProfiles = decodedToken.profile.startsWith('[') ? decodedToken.profile.substr(1, decodedToken.profile.length-2) : decodedToken.profile;
            const userProfiles = trimmedProfiles.split(', ');
            for (let profileItem of userProfiles) {
                if (allowedProfiles.indexOf(profileItem) > -1) {
                    this.userHasValidProfile = true;
                    break;
                }
            }
        }

        const isTokenValid = (decodedToken.exp * 1000) > Date.now();

        return this.userHasValidProfile && isTokenValid;
    }
}
