import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { AppAuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AppAuthGuard implements CanActivate{

    constructor(private authService: AppAuthService) {}
    
    canActivate(): boolean {
        return this.authService.userHasValidProfile;
    }

}