import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpParams
} from '@angular/common/http';
import {
  URL_POST_TSP_ORGANIZATIONS
} from 'src/common/urls';
import { RegulatedEntity } from 'src/common/models/regulated-entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(private http: HttpClient) { }

  public getList(pageIndex: string, pageSize: string, status: string) {
    let params = new HttpParams();
    params = params.set('pageIndex', pageIndex);
    params = params.set('pageSize', pageSize);
    params = params.set('status', status);
    params = params.set('time', Date.now().toString());
    return this.http.get<any>('events/list',
      { params, observe: "response" });
  }

  public addTspOrganization(newEntity: RegulatedEntity): Observable<boolean> {
    return this.http
      .post(URL_POST_TSP_ORGANIZATIONS, newEntity, { observe: 'response' })
      .pipe(map((x: HttpResponse<any>) => x.status === 201));
  }

  public resendItem(list: any[]) {
    const requestBody = list;
    const requestUrl = 'events/resendMessage';
    return this.http.post(requestUrl, { messageIds: requestBody });
  }

  public deleteItem(list: any[]) {
    const requestBody = list.join(',');
    const requestUrl = 'events/delete';
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-HSBC-Message-Ids': requestBody
      })
    };

    return this.http.delete(requestUrl, options);
  }

}
