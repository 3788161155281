import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ModifyTspComponent } from './modify-tsp/modify-tsp.component';
import { AppModifyTspResolver } from './modify-tsp/modify-tsp.resolver';
import { AppAuthGuard } from 'src/common/services/auth-guard.service';

const routes: Routes = [
  // { path: '', pathMatch: 'full', redirectTo: 'modify-tsp' },
  { path: '', component: ModifyTspComponent, data: { title: 'Message list' }, resolve: { tspList: AppModifyTspResolver }, canActivate: [AppAuthGuard] },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
