<div class="confirmation-popup-container">

    <section class="body">
        <div class="text" *ngFor="let text of textArray">{{text}}</div>
    </section>
    <section class="action">
        <button (click)="onButtonClicked(false)" *ngIf="secondaryButtonText !== null"> {{ secondaryButtonText }} </button>
        <button class="primary" (click)="onButtonClicked(true)" *ngIf="primaryButtonText !== null"> {{ primaryButtonText }} </button>
    </section>

</div>
