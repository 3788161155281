import { Injectable, Type } from "@angular/core";
import { AppPopupComponent } from "./popup.component";
import { Subject, combineLatest } from "rxjs";
import { IAppPopup } from "./popup.interface";
import { AppConfirmationPopupInput, AppConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { RegulatedEntity } from 'src/common/models/regulated-entity';
// import { AppModifyTspPopupComponent } from '../modify-tsp-popup/modify-tsp-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppPopupService {

  private popupComponentContext: AppPopupComponent;

  constructor(private translate: TranslateService) { }

  openPopup<PopupInputType, PopupOutputType>(popupComponent: Type<IAppPopup<PopupInputType, PopupOutputType>>,
    title: string,
    data: PopupInputType = null,
    showCloseButton: boolean = true): Subject<PopupOutputType> {
    return this.popupComponentContext.showPopup<PopupInputType, PopupOutputType>(popupComponent, title, data, showCloseButton);
  }

  setPopupComponentContext(newContext: AppPopupComponent) {
    this.popupComponentContext = newContext;
  }

  openConfirmationPopup(title: string, content: string, primaryButtonText: string = 'Yes', secondaryButtonText: string = 'No'): Subject<boolean> {
    return this.popupComponentContext.showPopup<AppConfirmationPopupInput, boolean>(AppConfirmationPopupComponent, title, new AppConfirmationPopupInput(content, primaryButtonText, secondaryButtonText), false);
  }

  // openModifyTspPopup(title: string, regulatedEntity: RegulatedEntity): Subject<RegulatedEntity> {
  //   return this.popupComponentContext.showPopup<RegulatedEntity, RegulatedEntity>(AppModifyTspPopupComponent, title, regulatedEntity, false);
  // }

  openMessagePopup(title: string, content: string, primaryButtonText: string = 'Ok', timeOutMS: number = 0): Subject<boolean> {
    return this.popupComponentContext.showPopup<AppConfirmationPopupInput, boolean>(AppConfirmationPopupComponent, title, new AppConfirmationPopupInput(content, primaryButtonText, null, timeOutMS), false);
  }

  openNonClosableMessagePopup(title: string, content: string, primaryButtonText: string = 'Ok'): Subject<boolean> {
    return this.popupComponentContext.showNonClosablePopup<AppConfirmationPopupInput, boolean>(AppConfirmationPopupComponent, title, new AppConfirmationPopupInput(content, primaryButtonText, null, 0));

  }

  openMessagePopupFnF(titleKey: string, contentKey: string, primaryButtonText: string = 'Ok', callback?: () => void): void {
    combineLatest(
      this.translate.get(titleKey),
      this.translate.get(contentKey)
    ).pipe(
      switchMap(([title, content]) => this.openMessagePopup(title, content, primaryButtonText))
    ).subscribe(() => { callback() });
  }
}
