import { HttpInterceptor, HttpEvent, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { tap, catchError } from 'rxjs/operators';
import { AppLoadingSpinnerService } from 'src/common/components/loading-spinner/loading-spinner.service';
import { of } from 'rxjs';
import { AppPopupService } from 'src/common/components/popup/popup.service';
import { AppUtility } from 'src/common/utility.class';
import { AppAuthService } from 'src/common/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AppHttpInterceptor implements HttpInterceptor {

    baseUrl: string = environment.BASE_URL;
    totalOngoingRequests: number = 0;

    constructor(private loaderService: AppLoadingSpinnerService, private popup: AppPopupService, private authService: AppAuthService) { }

    intercept(req: import("@angular/common/http").HttpRequest<any>, next: import("@angular/common/http").HttpHandler): import("rxjs").Observable<import("@angular/common/http").HttpEvent<any>> {
        if (this.shouldIntercept(req.url)) {
            const reqClone = req.clone({
                url: this.prependBaseUrl(req.url),
                headers: this.appendHeaders(req.headers)
            });
            this.setOngoingRequestCount(+1);

            return next.handle(reqClone).pipe(
                tap((x: HttpEvent<any>) => {
                    if (x instanceof HttpResponse) {
                        this.setOngoingRequestCount(-1);
                    }
                }),
                catchError((err, caught) => {
                    this.setOngoingRequestCount(-1);

                    if (err.status === 401) {
                        // Unauthorised, redirect to login url
                        const msg = 'You are unauthorized. Redirecting to the login page...';
                        this.popup.openMessagePopup('Error', msg, undefined, 5000).subscribe(() => {
                            window.location.assign(environment.LOGIN_URL);
                        });

                    } else {
                        let msg = `An error has occured. More information:\nStatus Code: ${err.status}`;
                        if (err.error.errorInfo) {
                            const errors = err.error.errorInfo.map(x => x.causes).flat().join('\n');
                            msg += '\n' + errors;
                        }
                        this.popup.openMessagePopup('Error', msg).subscribe(() => { });
                    }
                    return of(null);
                })
            );
        }

        return next.handle(req);
    }

    private prependBaseUrl(relativeUrl: string): string {
        return this.shouldPrependBaseUrl(relativeUrl) ? this.baseUrl + relativeUrl : relativeUrl;
    }

    private shouldPrependBaseUrl(relativeUrl: string): boolean {
        return !relativeUrl.startsWith('http');
    }

    private shouldIntercept(url: string): boolean {
        return !url.startsWith('/assets');
    }

    private setOngoingRequestCount(amount: number) {
        this.totalOngoingRequests += amount;
        if (this.totalOngoingRequests < 0) this.totalOngoingRequests = 0;
        this.loaderService.isLoading = this.totalOngoingRequests > 0;
    }

    private appendHeaders(existingHeaders: HttpHeaders): HttpHeaders {
        let modifiedHeaders = existingHeaders;

        const toAppendHeaders = {
            'Authorization': `Bearer ${this.authService.idToken ? this.authService.idToken : ''}`,
            'Accept': 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8',
            'X-HSBC-Request-Correlation-Id': AppUtility.getUUIDV4(),
            'X-HSBC-Src-UserAgent': 'Notification Management UI HTTP client',
            'X-HSBC-Chnl-Group-Member': 'Global',
            'X-HSBC-GBGF': 'WSIT'
        };

        for (let key in toAppendHeaders) {
            modifiedHeaders = modifiedHeaders.set(key, toAppendHeaders[key]);
        }

        return modifiedHeaders;
    }
}
