import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'comma'
})
export class AppCommaPipe implements PipeTransform {

    transform(value: string[]) {
        value = value ?? [];
        return value.join(", ");
    }

}