import { Component } from '@angular/core';
import { IAppPopup, OnCloseFn } from '../popup/popup.interface';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-confirmation-popup',
    templateUrl: './confirmation-popup.component.html',
    styleUrls: ['./confirmation-popup.component.scss']
})
export class AppConfirmationPopupComponent implements IAppPopup<AppConfirmationPopupInput, boolean> {
    
    textArray: string[] = [];
    primaryButtonText: string = '';
    secondaryButtonText: string = '';

    onCloseFn: any;
    timeoutId: any = null;

    onButtonClicked(result: boolean) {
        clearTimeout(this.timeoutId);
        this.onCloseFn(result);
    }
    
    setData(data: AppConfirmationPopupInput) {
        this.textArray = data.text.split('\n');
        this.primaryButtonText = data.primaryButtonText;
        this.secondaryButtonText = data.secondaryButtonText;

        if (data.timeOutMS > 0) {
            this.timeoutId = setTimeout(() => {
                this.onCloseFn(true);
            }, data.timeOutMS);
        }
    }
    registerOnClose(fn: OnCloseFn<boolean>) {
        this.onCloseFn = fn;
    }

}

export class AppConfirmationPopupInput {
    constructor(public text: string, public primaryButtonText: string = 'Yes', public secondaryButtonText: string = 'No', public timeOutMS: number = 0) {}
}