import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppLoadingSpinnerComponent } from 'src/common/components/loading-spinner/loading-spinner.component';
import { AppLoadingSpinnerService } from 'src/common/components/loading-spinner/loading-spinner.service';
import { AppAuthService } from 'src/common/services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter, map, pluck, switchMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { AppPopupService } from 'src/common/components/popup/popup.service';
import { environment } from 'src/environments/environment';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit {

  @ViewChild(AppLoadingSpinnerComponent, { static: true }) loadingSpinnerComponent: AppLoadingSpinnerComponent;

  constructor(private translate: TranslateService,
    private loadingSpinnerService: AppLoadingSpinnerService,
    private router: Router,
    private title: Title,
    private authService: AppAuthService,
    private popupService: AppPopupService) {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  ngOnInit(): void {
    this.initTitleHandler();
  }
  ngAfterViewInit(): void {
    this.loadingSpinnerService.registerLoadingSpinnerComponent(this.loadingSpinnerComponent);
    if (!this.authService.userHasValidProfile) {
      // show popup for unauthorized
      combineLatest(
        this.translate.get('popup.unauthorized.title'),
        this.translate.get('popup.unauthorized.content')
      ).pipe(
        switchMap(([title, content]) => this.popupService.openNonClosableMessagePopup(title, content, null))
      ).subscribe(() => {})
    }
  }

  private initTitleHandler() {
    this.router.events.pipe(
      filter(x => x instanceof NavigationEnd),
      pluck('urlAfterRedirects'),
      map((url: string) => this.router.config.find(x => x.path.length > 0 && url.indexOf(x.path) > -1))
    ).subscribe(x => {
      //const prefix = x.data?.title ?? null;
      const suffix = 'Notification Management UI';
      //const title = prefix ? `${prefix} - ${suffix}` : suffix;
      this.title.setTitle(suffix);
    });
  }
}
