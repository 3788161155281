import { Injectable } from "@angular/core";
import { AppLoadingSpinnerComponent } from "./loading-spinner.component";

@Injectable({
    providedIn: 'root'
})
export class AppLoadingSpinnerService {
    private spinnerContext: AppLoadingSpinnerComponent;

    registerLoadingSpinnerComponent(theSpinner: AppLoadingSpinnerComponent) {
        this.spinnerContext = theSpinner;
    }

    get isLoading(): boolean {
        return this.spinnerContext.visible;
    }
    set isLoading(visible: boolean) {
        this.spinnerContext.visible = visible;
    }
}