import { Component, ViewChild, Type, ComponentFactoryResolver, OnInit, ElementRef } from "@angular/core";
import { AppPopupHostDirective } from "./popup-host.directive";
import { AppPopupService } from "./popup.service";
import { IAppPopup } from "./popup.interface";
import { Subject, fromEvent } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { AppUtility } from "../../utility.class";

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class AppPopupComponent implements OnInit {

  title: string = '';
  open: boolean = false;
  hideContent: boolean = true;
  popupHandle$: Subject<any>;
  previousActiveElement = null;
  showCloseButton: boolean = true;

  isClosable: boolean = true;

  @ViewChild(AppPopupHostDirective) popupHost: AppPopupHostDirective;
  @ViewChild('popupElement') popupElement: ElementRef;

  constructor(private cfr: ComponentFactoryResolver, private sliderPopupService: AppPopupService, private el: ElementRef) {
  }

  ngOnInit() {
    this.sliderPopupService.setPopupComponentContext(this);
  }

  showPopup<PopupInputType, PopupOutputType>(popupComponent: Type<IAppPopup<PopupInputType, PopupOutputType>>,
    title: string,
    data: PopupInputType,
    showCloseButton: boolean): Subject<PopupOutputType> {
    this.hideContent = false;
    this.popupHost.viewContainerRef.clear();
    this.title = title;
    this.showCloseButton = showCloseButton;

    // spawn a component
    const factory = this.cfr.resolveComponentFactory(popupComponent);
    const componentRef = this.popupHost.viewContainerRef.createComponent(factory);
    componentRef.instance.setData(data); // send input data to popup
    componentRef.instance.registerOnClose(this.closePopup);

    // show the popup
    this.previousActiveElement = document.activeElement;
    AppUtility.disableBodyScroll();
    this.open = true;
    this.popupHandle$ = new Subject<PopupOutputType>();
    setTimeout(() => {
      this.setupFocusTrap();
    }, 300);
    return this.popupHandle$;
  }

  showNonClosablePopup<PopupInputType, PopupOutputType>(popupComponent: Type<IAppPopup<PopupInputType, PopupOutputType>>,
    title: string,
    data: PopupInputType
  ): Subject<PopupOutputType> {

    this.isClosable = false;
    return this.showPopup<PopupInputType, PopupOutputType>(popupComponent, title, data, false);
  }

  closePopup = (result: any) => {
    if (this.isClosable) {
      AppUtility.enableBodyScroll();
      this.open = false;
      this.popupHandle$.next(result);
      this.popupHandle$.complete();
      setTimeout(() => {
        this.hideContent = true;
        if (this.previousActiveElement) this.previousActiveElement.focus();
      });
    }
  };

  onCloseButtonClicked() {
    this.closePopup(null);
  }

  private setupFocusTrap() {
    const focusableElements = (this.el.nativeElement as HTMLElement).querySelectorAll('a, input, select, button, textarea, [role="button"], [tabindex="0"]');
    const firstFocusableElement = <HTMLElement>focusableElements[0];
    const lastFocusableElement = <HTMLElement>focusableElements[focusableElements.length - 1];

    if (firstFocusableElement) firstFocusableElement.focus();

    fromEvent<KeyboardEvent>(document.body, 'keydown').pipe(takeUntil(this.popupHandle$)).subscribe(ev => {
      if (ev.keyCode === 9) {
        // Tab is pressed
        if (ev.shiftKey && document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus();
          ev.preventDefault();
        } else if (document.activeElement === lastFocusableElement || document.activeElement === document.body) {
          firstFocusableElement.focus();
          ev.preventDefault();
        }
      } else if (ev.keyCode === 27) {
        // Escape is pressed
        this.onCloseButtonClicked();
      }
    })
  }
}
