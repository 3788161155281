<section class="popup-wrapper" [class.open]="open">
  <div class="popup-container" [style.visibility]="hideContent ? 'hidden' : 'visible'" #popupElement
    aria-labelledby="popup_title" role="dialog" aria-modal="true">
    <div class="header">
      <h2 class="title" id="popup_title" *ngIf="title && title.length > 0">{{ title }}</h2>
      <sg-icon *ngIf="showCloseButton" tabindex="0" role="button" aria-label="Close modal" icon="close"
        (click)="onCloseButtonClicked()" (keydown.enter)="onCloseButtonClicked()"></sg-icon>
    </div>
    <div class="content" aria-live="polite" [attr.aria-hidden]="!open">
      <ng-template popupHost></ng-template>
    </div>
  </div>
  <div class="popup-backdrop" *ngIf="open" (click)="onCloseButtonClicked()"></div>
</section>