<app-header></app-header>
<main>
  <section class="container">
    <router-outlet></router-outlet>

    <!-- Reusable popup container for all the popups -->
    <app-popup></app-popup>
  </section>
</main>

<app-loading-spinner></app-loading-spinner>