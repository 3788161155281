import { DecimalPipe } from "@angular/common";
import { v4 as uuidV4 } from "uuid";

export class AppUtility {
  public static disableBodyScroll(): void {
    if (document.body.className.indexOf('stop-scroll') === -1) {
      document.body.className += ' stop-scroll';
    }
  }
  public static enableBodyScroll(): void {
    const newClasses = document.body.className.split(' ').filter(x => x !== 'stop-scroll').join(' ');
    document.body.className = newClasses;
  }
  public static getUUIDV4(): string {
    return uuidV4();
  }
  public static parseLocationHashStringToObject(locationHash: string): any {
    if (locationHash.length === 0 || locationHash[0] !== '#') {
      return null;
    }
    const splits = locationHash.substr(1).split('&').map(s => { 
      const i = s.indexOf('=');
      return { [s.substr(0, i)]: s.substr(i+1) };
    });

    const output = Object.assign({}, ...splits);
    return output;
  }
  public static setSessionStorageItem(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }
  public static getSessionStorageItem(key: string): string {
    return sessionStorage.getItem(key);
  }

  public static getTranslateKey(errors: any): {key: string, param: object } {
    const prefix = "forms.error";
    const keys = Object.keys(errors) || [];
    if (keys.length > 0) {
        return { key: `${prefix}.${keys[0]}`, param: errors[keys[0]] };
    }
    return { key: `${prefix}.unknown`, param: null };
}
}
