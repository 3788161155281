import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PCMCommonModule } from 'src/common/common.module';
import { ModifyTspComponent } from './modify-tsp/modify-tsp.component';
import { AppHttpInterceptor } from './app.interceptor';
import { PaginationModule } from './pagination/pagination.module';
import { AppAuthService } from 'src/common/services/auth.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function authInitializeFactory(authService: AppAuthService) {
  return () => authService.initialize();
}

@NgModule({
  declarations: [
    AppComponent,
    ModifyTspComponent
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),
    HttpClientModule,
    AppRoutingModule,
    PCMCommonModule,
    PaginationModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: AppHttpInterceptor },
    { provide: APP_INITIALIZER, multi: true, useFactory: authInitializeFactory, deps: [AppAuthService] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
