import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'piggy-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']

})
export class PaginationComponent implements OnInit, OnChanges {

  @Input() currentPageIndex = 0;
  @Input() pagesAmount: number;
  pagesIndexes: number[];
  destinatedPageIndex = 0;

  @Output() changePageEvent: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
  }

  changePage(pageIndex: number): void {
    this.changePageEvent.emit(pageIndex);
  }

  setDestination(pageIndex: number): void {
    this.destinatedPageIndex = pageIndex - 1;
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.pagesAmount) {
      this.pagesIndexes = Array.from({length: this.pagesAmount}, (_, index) => index + 1);
    }
  }

  ngOnInit(): void {
    this.pagesIndexes = Array.from({length: this.pagesAmount}, (_, index) => index + 1);
  }

}
